import OurServiceStyle from "../Styles/OurService.styles";
import Building from '../images/building.svg';
import Machine from '../images/machine.svg';
import Flag from '../images/flag.svg';

const CardService = ({cName ,type, title , content}) => (
  <>
    <div className={cName}>
      <img src={type} alt="" />
      <p>{title}</p>
      <span>{content}</span>
    </div>
  </>
);
const OurService = _ => (
  <OurServiceStyle id="os">
    <p>Our Services</p>
    <section>
      <CardService cName={'building'} type={Building} title={'Architectural Design'} content={'Initial architectural taken.'} />
      <CardService cName={'cw'} type={Machine} title={'Construction Work'} content={'Complete construction from foundation, wall, roofing, to flooring.'} />
      <CardService cName={'fuf'} type={Flag} title={'Finishing'} content={'All hassle of final finishing of electricity wiring, wood work.'} />
    </section>
  </OurServiceStyle>
);

export default OurService;
